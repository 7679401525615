import React, { useState } from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import PredioPalmCoast from '../../images/PalmCoastFachada.png'
import { Carousel } from "react-responsive-carousel";
import picarrasAerea from '../../images/picarrasAerea.jpeg'
import Progress from '../../components/progress'
import LogoPalmSemFundo from '../../images/LogoPalmSemFundo.png'
import PalmCoast101 from '../../images/PalmCoast101.png'
import PalmCoast102 from '../../images/PalmCoast102.png'
import PalmCoast201 from '../../images/PalmCoast201.png'
import PalmCoast202 from '../../images/PalmCoast202.png'
import PalmCoast203 from '../../images/PalmCoast203.png'
import PalmCoast401 from '../../images/PalmCoast401.png'
import ImageModal from "../../components/imageModal"
//import Timeline from '../../components/timeLine';

export default function PalmCoast() {
    const [img, setImg] = useState('')
    const [imageVisible, setImageVisible] = useState(false)
    const [plant, setPlant] = useState(0)

    function handleClick(key) {
        if (key === 0) {
            setImg(PalmCoast101)
        }
        else if (key === 1) {
            setImg(PalmCoast102)
        }
        else if (key === 2) {
            setImg(PalmCoast201)
        }
        else if (key === 3) {
            setImg(PalmCoast202)
        }
        else if (key === 4) {
            setImg(PalmCoast203)
        }
        else {
            setImg(PalmCoast401)
        }
        setImageVisible(true)
    }
    return (
        <Layout image={<img className='mb-3 w-2/4' src={LogoPalmSemFundo}></img>}>
            <SEO
                keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
                title="Palm Coast"
            />
            <ImageModal {...{ imageVisible, setImageVisible, img }} />

            <h2 className='my-16 italic text-center'>O Empreendimento Palm Coast tem tudo que a linha Bivver CLub oferece: espaço, conforto e sofisticação.</h2>
            <h3 className='text-center'>Venha viver bem no Palm Coast! Faça parte do Bivver Club</h3>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center'>
                <img className='lg:w-3/5 lg:ml-24' src={PredioPalmCoast} />
                <p className='text-md'>Localizado a 250m da praia do Itacolomi, o edifício conta com poucos
apartamentos proporcionando maior privacidade aos moradores. Além
disso tem arquitetura moderna e utiliza as tecnologias mais avançadas
como a laje protendida, proporcionando uma melhor utilização dos
espaços.<br />
                    <br />
                    O Empreendimento tem elevador de primeira linha, hall social e salão de
festas. Conta também com estrutura para tubulação para ar condicionado
Split, medidores de água e gás individuais, portão e porteiro eletrônicos e
garagem coberta.<br />
                    <br />
                    São apartamentos de 2 suíte mais 1 dormitório ou 1 suíte mais 1
dormitório, sendo que a cobertura conta com 1 suíte master e mais 2
suíte!</p>
            </div>
            <h2 className='my-10 text-center '>Plantas</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
                <Carousel
                    infiniteLoop
                    dynamicHeight={true}
                    swipeable={true}
                    selectedItem={plant}
                    showStatus={false}
                    stopOnHover
                    onClickItem={(key) => handleClick(key)}
                    onChange={(key) => setPlant(key)}
                    className='plan-carousel link'
                >
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast101}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast102}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast201}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast202}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast203}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={PalmCoast401}
                    />
                </Carousel>
                {plant === 0 ?
                    <div className='ml-10'>
                        <h3>Apartamento 101 - 89,64m²</h3>
                        <div className='mt-8'>
                            <div className='my-2'>1 Suíte</div>
                            <div className='my-2'>1 Dormitório</div>
                            <div className='my-2'>Sala de jantar</div>
                            <div className='my-2'>Sala de estar</div>
                            <div className='my-2'>Cozinha</div>
                            <div className='my-2'>Área de serviço</div>
                            <div className='my-2'>Banheiro social</div>
                            <div className='my-2'>Sacada</div>
                        </div>
                    </div>
                    :
                    plant === 1 ?
                        <div className='ml-10'>
                            <h3>Apartamento 102 - 82,50m²</h3>
                            <div className='mt-8'>
                                <div className='my-2'>1 Suíte</div>
                                <div className='my-2'>1 Dormitório</div>
                                <div className='my-2'>Sala de jantar</div>
                                <div className='my-2'>Sala de estar</div>
                                <div className='my-2'>Cozinha</div>
                                <div className='my-2'>Área de serviço</div>
                                <div className='my-2'>Banheiro social</div>
                                <div className='my-2'>Sacada</div>
                                <div className='my-2'>Terraço descoberto</div>
                            </div>
                        </div>
                        : plant === 2 ?
                            <div className='ml-10'>
                                <h3>Apartamento 201 e 301 - 60,02m²</h3>
                                <div className='mt-8'>
                                    <div className='my-2'>1 Suíte</div>
                                    <div className='my-2'>1 Dormitório</div>
                                    <div className='my-2'>Sala de jantar</div>
                                    <div className='my-2'>Sala de estar</div>
                                    <div className='my-2'>Cozinha</div>
                                    <div className='my-2'>Área de serviço</div>
                                    <div className='my-2'>Banheiro social</div>
                                    <div className='my-2'>Sacada</div>
                                    <div className='my-2'>Terraço descoberto</div>
                                </div>
                            </div>
                            : plant === 3 ?
                                <div className='ml-10'>
                                    <h3>Apartamento 202 e 302 - 62,73m²</h3>
                                    <div className='mt-8'>
                                        <div className='my-2'>1 Suíte</div>
                                        <div className='my-2'>1 Dormitório</div>
                                        <div className='my-2'>Sala de jantar</div>
                                        <div className='my-2'>Sala de estar</div>
                                        <div className='my-2'>Cozinha</div>
                                        <div className='my-2'>Área de serviço</div>
                                        <div className='my-2'>Banheiro social</div>
                                        <div className='my-2'>Sacada</div>
                                    </div>
                                </div>
                                : plant === 4 ?
                                    <div className='ml-10'>
                                        <h3>Apartamento 203 e 303 - 56,31m²</h3>
                                        <div className='mt-8'>
                                            <div className='my-2'>2 Dormitório</div>
                                            <div className='my-2'>Sala de jantar</div>
                                            <div className='my-2'>Sala de estar</div>
                                            <div className='my-2'>Cozinha</div>
                                            <div className='my-2'>Área de serviço</div>
                                            <div className='my-2'>Banheiro social</div>
                                            <div className='my-2'>Sacada</div>
                                        </div>
                                    </div>
                                    :
                                    <div className='ml-10'>
                                        <h3>Cobertura - 165,09m²</h3>
                                        <div className='mt-8'>
                                            <div className='my-2'>1 Suíte Master</div>
                                            <div className='my-2'>2 Suítes</div>
                                            <div className='my-2'>1 Dormitório</div>
                                            <div className='my-2'>Sala de jantar</div>
                                            <div className='my-2'>Sala de estar</div>
                                            <div className='my-2'>Sala de TV</div>
                                            <div className='my-2'>Cozinha Gourmet</div>
                                            <div className='my-2'>Área de serviço</div>
                                            <div className='my-2'>Banheiro social</div>
                                            <div className='my-2'>Sacada</div>
                                            <div className='my-2'>Área externa privativa</div>
                                            <div className='my-2'>Infra para SPLIT, hidromassagem e ofurô</div>
                                        </div>
                                    </div>
                }
            </div>
            <br />
            <br />
            <center><h2>Acompanhamento da obra</h2></center>
            <center><h5>Data de inicio: 09/2019 | Data de término : 07/2021</h5></center>
            <br />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Terraplanagem</h4>
                <Progress percentage='100' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Fundação</h4>
                <Progress percentage='100' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Estrutura</h4>
                <Progress percentage='81' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Alvenaria</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Instalações</h4>
                <Progress percentage='42' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Reboco Interno</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Reboco Externo</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Ceramica</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Esquadrias</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Pinturas</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Revestimento em Gesso</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Instalacao do Elevador</h4>
                <Progress percentage='0' />
            </div>
            {/* <h2 className='text-center mt-8'>Linha do tempo</h2>
            <Timeline>
                <div>
                <img className='p-8' src={janeiro} />
                <p className='text-center'>aaaaaaaaaaaaaa</p>
                </div>
                <img className='p-8' src={fevereiro} />
                <img className='p-8' src={marco} />
                <img className='p-8' src={abril} />
                <img className='p-8' src={junho} />
                <img className='p-8' src={julho} />
                <img className='p-8' src={agosto} />
            </Timeline> */}

            <div>
                <h2 className="font-bold text-center my-10">Localização</h2>
                <iframe
                    className='lg:w-1/2 w-full lg:mx-64 border-solid border-2 border-blue-500'
                    title="SoftinLocationMap"
                    height="360"
                    frameBorder="0"
                    src="https://maps.google.com/maps?q=rua%20floresta%2C%20148.%20Pi%C3%A7arras&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    allowFullScreen=""
                />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-24 self-center'>
                <div>
                    <h2 className='mb-8'>Por que morar em Piçarras?</h2>
                    <p className='text-sm'>Com uma localização geográfica privilegiada,
                    ficando aproximadamento 20 quilômetros do aeroporto de Navegantes e ligada por vias pavimentadas a BR-101,
                    o que garante acesso rápido a outros polos de diversão e passeio, como o parque temático de Beto Carrero World, em Penha,
                    a famosa Oktoberfest em Blumenau e ótimas compras em Brusque.
                    <br />
                        <br />
                    O Balneário Piçarras abrange belas praias que possuem bandeira
                    azul (certificado mundial de balneabilidade), tranquilidade e ótimcas opções de entretenimento em família. Com uma excelente
                    infraestrutura, a cidade está preparada para recepcionar da melhor forma possível turistas de todos os cantos do mundo.
                    Pousadas, bares, casas noturnas, mercados e restaurantes que oferecem o famoso rodízio de frutos do mar, são apenas uma pequena
                    amostra do que Piçarras tem a oferecer.
                    </p>
                </div>
                <img src={picarrasAerea} className='self-center' />
            </div>
        </Layout>)
}